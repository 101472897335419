import React from "react";
import styled from "styled-components";
import Uploader from "../Uploader";
import Translate from "../../../config/Translate";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../features/getUserSlice";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as Tick } from "../../../assets/icons/tick.svg";
import { ReactComponent as CancelSvg } from "../../../assets/icons/close.svg";
import "react-loading-skeleton/dist/skeleton.css";
import { useState } from "react";
import { useEffect } from "react";

const LeftProfileData = () => {
  let getUserInfo = useSelector(selectUserInfo).payload;
  let hostname = window.location.hostname
    .split(".")
    .slice(1)
    .slice(-2)
    .join(".");
  const fullName = useRef();
  const email = useRef();
  const city = useRef();
  const phone = useRef();
  const postalcode = useRef();
  const [withEmail, setwithEmail] = useState(null);
  const [withSms, setwithSms] = useState(null);
  const [withWhatsapp, setwithWhatsapp] = useState(null);
  const getProData = () => {
    if (getUserInfo) {
      fullName.InnerText = `${getUserInfo?.first_name} ${getUserInfo?.last_name}`;
      email.InnerText = `${getUserInfo?.email} `;
      city.InnerText = `${getUserInfo?.city} `;
      phone.InnerText = `${getUserInfo?.phone} `;
      postalcode.InnerText = `${getUserInfo?.postal_code} `;
      setwithEmail(getUserInfo?.contact_with_email);
      setwithSms(getUserInfo?.contact_with_sms);
      setwithWhatsapp(getUserInfo?.contact_with_whatsapp);
    }
  };

  useEffect(() => {
    getProData();
  }, [getUserInfo]);

  return (
    <LeftContainer className="left-profil-container">
      <Datacardsticky>
        <DataCards>
          <Uploader
            Firstname={getUserInfo?.first_name ? getUserInfo?.first_name : "N"}
            LastName={getUserInfo?.last_name ? getUserInfo?.last_name : "A"}
          />
          <FullName ref={fullName}>
            {fullName.InnerText ? (
              fullName.InnerText
            ) : fullName.InnerText === null || fullName.InnerText === "" ? (
              "N/A"
            ) : (
              <Skeleton />
            )}
          </FullName>
          <DataContainer className="datacontainer-profil">
            <h4>
              <Translate Word="page.EmailInput" />
            </h4>
            <p
              ref={email}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {email.InnerText ? (
                email.InnerText
              ) : email.InnerText === null || email.InnerText === "" ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>
              <Translate Word="page.CityInput" />
            </h4>
            <p
              ref={city}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {city.InnerText ? (
                city.InnerText
              ) : city.InnerText === null || city.InnerText === "" ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>
              <Translate Word="page.PhoneInput" />
            </h4>
            <p
              ref={phone}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {phone.InnerText ? (
                phone.InnerText
              ) : phone.InnerText === null ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>
              <Translate Word="page.PostalCodeInput" />
            </h4>
            <p
              ref={postalcode}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {postalcode.InnerText ? (
                postalcode.InnerText
              ) : postalcode.InnerText === null ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
        </DataCards>
        <TitleHeader>
          <Translate Word="page.contact_with" />{" "}
        </TitleHeader>
        <DataCards>
          <DataContainer>
            <h4>
              <Translate Word="sidebar.email" />
            </h4>
            <p
              className="contactWith"
              style={{ width: "70%", textAlign: "right" }}
            >
              {withEmail == "yes" && <Tick />}
              {withEmail == "no" && <CancelSvg />}
              {withEmail ? (
                withEmail === "yes" ? (<Translate Word="page.general.yes"></Translate>) : (<Translate Word="page.general.no"></Translate>)
              ) : withEmail === null ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>SMS</h4>
            <p
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
              className="contactWith"
            >
              {withSms == "yes" && <Tick />}
              {withSms == "no" && <CancelSvg />}
              {withSms ? withSms === "yes" ? (<Translate Word="page.general.yes"></Translate>) : (<Translate Word="page.general.no"></Translate>) : withSms === null ? "N/A" : <Skeleton />}
            </p>
          </DataContainer>
          {hostname != "evc.clinic" && (
            <DataContainer>
              <h4>Whatsapp</h4>
              <p
                style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
                className="contactWith"
              >
                {withWhatsapp == "yes" && <Tick />}
                {withWhatsapp == "no" && <CancelSvg />}
                {withWhatsapp ? (
                   withWhatsapp === "yes" ? (<Translate Word="page.general.yes"></Translate>) : (<Translate Word="page.general.no"></Translate>)
                ) : withWhatsapp === null ? (
                  "N/A"
                ) : (
                  <Skeleton />
                )}
              </p>
            </DataContainer>
          )}
        </DataCards>
      </Datacardsticky>
    </LeftContainer>
  );
};

export default LeftProfileData;

const LeftContainer = styled.div`
  flex: 1;
  padding: 20px;
  padding-top: 0;
  @media screen and (max-width: 500px) {
    padding: 10px;
    padding-top: 0;
    width: 100%;
  }
`;
const DataContainer = styled.div`
  min-width: 350px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-top: 1px solid #e9e9e9e8;
  @media screen and (max-width: 500px) {
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 100%;
  }
`;

const FullName = styled.p`
  margin-bottom: 20px;
  color: #1c2232;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;
const DataCards = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  padding: 24px;
`;
const Datacardsticky = styled.div`
  position: sticky;
  top: 100px;
`;

const TitleHeader = styled.h1`
  color: #1c2232;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 12px;
  font-weight: 500;
`;
