import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SignUp from "../components/SignUp";
import BannerEvc from "../assets/images/evcBg.png";
import CrissierBanner from "../assets/images/crissier_banner.jpg";
import Pully from "../assets/images/pully_banner.jpeg";
import Geneve from "../assets/images/geneve.webp";
import Banner from "../assets/images/banner_right.jpg";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  let hostname = window.location.hostname.split(".").slice(-2).join(".");
  let host = window.location.hostname;
  console.log("-----------------host--------------", host);
  let bgImage = Banner;
  if (hostname == "evc.clinic") {
    bgImage = BannerEvc;
  } else if (hostname == "vetmidi.org") {
    bgImage = Banner;
  } 
  else if (host == "crissier.digimidi.ch") {
    bgImage = CrissierBanner;
  }
  else if (host == "geneve.digimidi.ch") {
    bgImage = Geneve;
  }
  else if (host == "pully.digimidi.ch") {
    bgImage = Pully;
  }
  else {
    bgImage = Banner;
  }
  const user_verified = JSON.parse(localStorage.getItem("user_verified"));
  const Checkauth = () => {
    if (user_verified) {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    Checkauth();
  }, []);
  return (
    <Container style={{ backgroundImage: `url(${bgImage})` }}>
      <LeftContainer>
        <SignUp />
      </LeftContainer>
      {hostname != "evc.clinic" &&  host != "crissier.digimidi.ch" && <Overlay />}
      {hostname == "evc.clinic" && (
        <RightContainer
          style={{ backgroundImage: `url(${bgImage})` }}
        ></RightContainer>
      )}
      {host == "crissier.digimidi.ch" && (
        <RightContainer
          style={{ backgroundImage: `url(${CrissierBanner})` }}
        ></RightContainer>
      )}
    </Container>
  );
};

export default Register;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  color: white;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000085;
`;

const LeftContainer = styled(Overlay)`
  width: 500px;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
  overflow-y: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
  @media screen and (max-width: 500px) {
    width: 100vw;
  }
`;

const RightContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 0;
  color: white;
  @media screen and (max-width: 500px) {
    width: 0;
  }
`;
