import secondLogo from "../assets/images/secondLogo.png";
import evc from "../assets/images/evc.png";
import dev from "../assets/images/dev.png";
import crissier from "../assets/images/crissier.png";
import geneve from "../assets/images/geneve_logo.png";
import pully from "../assets/images/pully_logo.png";
let hostname = window.location.hostname.split(".").slice(-2).join(".");
export const companyInfo = () => {
  if (hostname == "vetmidi.org" || window.location.hostname == "vetmidi.org") {
    return {
      cname: "VETMIDI",
      CimagePath: secondLogo,
      cdescription: "Urgences vétérinaires",
    };
  } else if (
    hostname == "evc.clinic" ||
    window.location.hostname == "evc.clinic"
  ) {
    return {
      cname: "EVC",
      CimagePath: evc,
      cdescription: "Urgences vétérinaires",
    };
  } else if (
    hostname == "crissier.digimidi.ch" ||
    window.location.hostname == "crissier.digimidi.ch"
  ) {
    return {
      cname: "CRISSIER",
      CimagePath: crissier,
      cdescription: "Urgences vétérinaires",
    };
  }
  else if (
    hostname == "geneve.digimidi.ch" ||
    window.location.hostname == "geneve.digimidi.ch"
  ) {
    return {
      cname: "GENEVE",
      CimagePath: geneve,
      cdescription: "Urgences vétérinaires",
    };
  }
  else if (
    hostname == "pully.digimidi.ch" ||
    window.location.hostname == "pully.digimidi.ch"
  ) {
    return {
      cname: "PULLY",
      CimagePath: pully,
      cdescription: "Urgences vétérinaires",
    };
  }
   else {
    return {
      cname: "dev",
      CimagePath: dev,
      cdescription: "N/A",
    };
  }
};
